<template>
  <div class="register">
    <div class="content">
      <div class="register__row">
        <RegisterSteps
          v-if="!hideLeft"
          :description="description"
          :title="'Восстановление пароля'"
        />
        <RestoreForm @changeStep="changeStep" />
      </div>
    </div>
  </div>
</template>

<script>
import RegisterSteps from "../../components/auth/RegisterSteps.vue";
import RestoreForm from '../../components/auth/RestoreForm.vue';

export default {
  metaInfo: {
      title: 'Восстановление пароля',
  },
  name: "RestorePassword",
  data: () => ({
    description: "На указанный email придет письмо для сброса пароля",
    hideLeft: false,
  }),
  methods: {
    changeStep(description, hideLeft) {
      if(hideLeft) {
        this.hideLeft = true
      }
      this.description = description;
    },
  },
  components: { RegisterSteps, RestoreForm },
};
</script>

<style lang="scss" scoped>
.register {
  height: 100%;
  padding: 64px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 1220px) {
    padding: 40px 0;
    height: auto;
  }
  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>